import React, { useState } from 'react';
import { Create, SaveButton, SimpleForm, TextInput, Toolbar, useRedirect } from 'react-admin';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';

interface IPropsAdministratorsCreate {}

export const AdministratorsCreate = (props: IPropsAdministratorsCreate) => (
    <Create redirect={'list'} {...props}>
      <SimpleForm>
        <TextInput source="login" />
        <TextInput source="password" />
        <TextInput source="phone" />
      </SimpleForm>
    </Create>
);
