import * as React from 'react';
import { TextField, Show, SimpleShowLayout, DateField } from 'react-admin';

export const AdministratorsShowLayout = () => (
    <SimpleShowLayout>
      <TextField source="id" sortable={false} />
      <TextField source="login" sortable={false} />
      {/* <TextField source="password" sortable={false} /> */}
      <TextField source="phone" sortable={false} />
      <DateField source="createAt" showTime sortable={false} />
      <DateField source="deletedAt" showTime sortable={false} />
    </SimpleShowLayout>
);

export const AdministratorsPostShow = () => (
  <>
    <Show>
      <AdministratorsShowLayout />
    </Show>
  </>
);
