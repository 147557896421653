/* eslint-disable no-underscore-dangle */
import { dataProviderAdmin } from './administrators';
import { dataProviderClient } from './clients';

class CompositeDataProvider {
  public dataProviders: any[] = [];

  constructor(dataProviders: any) {
    this.dataProviders = dataProviders;
  }

  _delegate(name: any, resource: any, params: any): any {
    const { dataProvider }: any = this.dataProviders.find((dp) => dp.resources.includes(resource));
    
    return dataProvider[name](resource, params);
  }

  getList(resource: any, params: any) {
    return this._delegate('getList', resource, params);
  }

  getOne(resource: any, params: any) {
    return this._delegate('getOne', resource, params);
  }

  getMany(resource: any, params: any) {
    return this._delegate('getMany', resource, params);
  }

  getManyReference(resource: any, params: any) {
    return this._delegate('getManyReference', resource, params);
  }

  create(resource: any, params: any) {
    return this._delegate('create', resource, params);
  }

  update(resource: any, params: any) {
    return this._delegate('update', resource, params);
  }

  updateMany(resource: any, params: any) {
    return this._delegate('updateMany', resource, params);
  }

  delete(resource: any, params: any) {
    return this._delegate('delete', resource, params);
  }

  deleteMany(resource: any, params: any) {
    return this._delegate('deleteMany', resource, params);
  }
}

export const dataProvider = new CompositeDataProvider([
  {
    dataProvider: dataProviderAdmin,
    resources: ['admin']
  },
  {
    dataProvider: dataProviderClient,
    resources: ['clients']
  }
]);
