import { tokenFieldName } from 'constants/auth/index';
import RestAPI from 'api/rest';

export const AuthAPI = {
  // called when the user attempts to log in
  login: async ({ username, password }: any) => {
    const login = username;
    const { data } = await RestAPI.post('/api/admin/signIn', { login, password });
    if (data) {
      localStorage.setItem(tokenFieldName, data.token);
      return Promise.resolve();
    }
    return Promise.reject();
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem(tokenFieldName);
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (error: any) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem(tokenFieldName);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => localStorage.getItem(tokenFieldName) ? Promise.resolve() : Promise.reject(),

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve()
};
