import React, { useState } from 'react';
import { BooleanInput, Edit, ImageField, ImageInput, ReferenceInput, SimpleForm, TextInput, useEditContext } from 'react-admin';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';

interface IPropsAdministratorsCreate {}

export const ClientsEdit = (props: IPropsAdministratorsCreate) => {
  const record = useEditContext();
  console.log(record);
  return (
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="phone" />
        <TextInput defaultValue="" source='passwordField' />
        <TextInput type="email" source="email" />
        <ImageInput source="picture" label="Pictures">
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Remember" source="remember" />
      </SimpleForm>
  );
};


export const ClientsPostEdit = () =>
  <>
    <Edit mutationMode='optimistic'>
      <ClientsEdit />
    </Edit>
  </>;