import React, { useState } from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';

interface IPropsAdministratorsCreate {}

export const AdministratorsEdit = (props: IPropsAdministratorsCreate) =>
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="login" />
        <TextInput source="phone" />
        {/* <TextInput source="password" /> */}
      </SimpleForm>
    </Edit>;
