import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, useLogout } from 'react-admin';
import { MenuItem } from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';

const UserLogoutButton = forwardRef((props, ref: any) => {
  const logout = useLogout();
  const handleClick = () => logout();
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon /> Logout
    </MenuItem>
  );
});

const UserMenuHeader = () => (
  <UserMenu>
    <UserLogoutButton />
  </UserMenu>
);

export const UserMenuAppBar = () => <AppBar userMenu={<UserMenuHeader />} />;
