/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-empty */
import { stringify } from 'query-string';
import RestAPI from 'api/rest';
import { apiUrl } from 'constants/index';
import { httpClient } from 'admin';

// export const AdministratorsAPI = {
//   getList: async (resource: any, params: any) => {
//     const { page } = params.pagination;
//     const quantity = params.pagination.perPage;
//     const searchByLot = params.filter.search;
//     const searchById = params.filter.byId;
//     const query = {
//       page,
//       quantity,
//       lotid: searchById,
//       lotnumber: searchByLot
//     };
//     return RestAPI.get(`/api/${resource}/all/?${stringify(query)}`).then(({ data }) => ({
//       data: data.admins,
//       total: data.meta.total
//     }));
//   },

//   // create: (resource: any, params: { data: any }) => 
//   //   RestAPI.post(`/api/${resource}/create`, params.data).then((json: any) => {
//   //     console.log(json);
//   //     console.log(params.data);
//   //     return { data: { ...params.data, id: json.data.id } };
//   //   })

//   // create: async (resource: string, params: any) => RestAPI.post(`/api/${resource}/create`, params.data).then(({ data }) => ({
//   //   data: { ...params.data, id: data.id }
//   // }))

//   // create: (resource: any, params: { data: any; }) => 
//   // // try {
//   // //   return RestAPI.post(`${apiUrl}/${resource}/create`, {
//   // //     method: 'POST',
//   // //     body: JSON.stringify(params.data),
//   // //   }).then(({ json }: any) => {
//   // //     Promise.resolve({
//   // //       data: { ...params.data, id: json.id },
//   // //     });
//   // //     return {
//   // //       data: { ...params.data, id: json.id },
//   // //     };

//   // //   }
//   // //   );
//   // // } catch (err) {
//   // //   Promise.reject(err);
//   // // }

//   //   RestAPI.post(`/api/${resource}/create`, params.data).then(({ json }: any) => 
//   //     // Promise.resolve({
//   //     //   data: { ...params.data, id: json.id },
//   //     // });
//   //     // Promise.reject(json);
//   //     ({
//   //       data: { ...params.data, id: json.id },
//   //     })

    
//   //   )
// };

export const dataProviderAdmin = {

  createAdmin: 'create',
  createClient: '',

  getAdmins: 'all',
  getClients: 'all',

  getList: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      page,
      quantity: perPage
    };

    // console.log(httpClient(url).then(({ headers, json }: any) => console.log(json)) );

    return RestAPI.get(`/api/${resource}/all/?${stringify(query)}`).then(({ data }) => ({
      data: data.admins,
      total: data.meta.total
    }));
  },

  getOne: (resource: any, params: { id: any }) => RestAPI.get(`/api/${resource}/${params.id}`).then(({ data }: any) => ({ data, id: params?.id }) ),

  getMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const url = `${`${process.env.REACT_APP_API_URL}/api`}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json.map((resource: { _id: any }) => ({ ...resource, id: resource._id }))
    }));
  },

  getManyReference: (
    resource: any,
    params: {
      pagination: { page: any; perPage: any };
      sort: { field: any; order: any };
      filter: any;
      target: any;
      id: any;
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id
      })
    };
    const url = `${`${process.env.REACT_APP_API_URL}/api`}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }: any) => ({
      data: json.map((resource: { _id: any }) => ({ ...resource, id: resource._id })),
      total: parseInt(headers.get('content-range').split('/').pop(), 10)
    }));
  },

  update: (resource: any, params: { id: any; data: any }) =>
    httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ ...json, id: json._id })),

  updateMany: (resource: any, params: { ids: any; data: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource: any, params: { data: any }) =>
    RestAPI.post(`/api/${resource}/create`, params.data).then(({ data }: any) => 
      // console.log(data);
      // console.log(params.data);
      ({ data: { ...params.data, id: data.id } })
    ),

  // httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}/create`, {
  //   method: 'POST',
  //   body: JSON.stringify(params.data)
  // }).then(({ json }) => {
  //   console.log(json);
  //   return { data: { ...params.data, id: json.id } };
  // }),

  delete: (resource: any, params: { id: any }) =>
    httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}/${params.id}`, {
      method: 'DELETE'
    }).then(({ json }) => ({ ...json, id: json._id })),

  deleteMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  }
};