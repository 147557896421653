/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { dataProviderClient } from 'api/payway/clients';
import Button from '@mui/material/Button';
import {
  TextField,
  Show,
  SimpleShowLayout,
  DateField,
  useShowContext,
  Form,
  Labeled,
  useNotify,
  useRefresh
} from 'react-admin';
import { UserShowActions } from './ClientsShowActions';

const ClientForm = (props: any) => {
  props;
  
  const notify = useNotify();
  const refresh = useRefresh();
  const { currentClient } = props;

  const { id, deletedAt } = currentClient;
  const colorActivityStatus = currentClient?.deletedAt ? 'success' : 'error';
  
  const handleSubmit = async (values: any) => {
    const { email, name, phone, confirmed, picture } = currentClient;
    const requestBody = {
      email,
      name,
      phone,
      remember: confirmed,
      picture,
    };

    try {
      await dataProviderClient.changeClientActivityStatus({ requestBody, id });
      await dataProviderClient.getOne('clients', { id });
      refresh();
      notify('Cтатус успешно изменен', { type: 'success' });
    } catch (error) {
      notify('Что-то пошло не так', { type: 'warning' });
    }
  };
  
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item>
          <Typography variant="h5" style={{ marginBottom: '1rem' }}>
            Change client activity 
          </Typography>
          <Grid spacing={2} container style={{ display: 'flex' }}>
            <Grid item>
              {/* <Labeled label="Client status">
                <SelectInput
                  onChange={handleActivityStatus}
                  // required
                  source="activityStatus"
                  label={'Client status'}
                  choices={[
                    { id: '1', name: 'Activate' },
                    { id: '2', name: 'Deactivate' }
                  ]}
                />
              </Labeled> */}
              <Labeled label="Client status">
               <Button onClick={handleSubmit} color={ colorActivityStatus } variant="outlined"> { deletedAt ? 'Activate' : 'Deactivate' }</Button>
              </Labeled>
            </Grid>

          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <SaveButton label="Save" />
        </Grid> */}
      </Grid>
    </Form>
  );
};

const ClientsShowLayout = (props: any) => {
  const { record } = useShowContext();

  return (
    <>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <TextField source="email" sortable={false} />
        <TextField source="name" sortable={false} />
        {/* <TextField source="password" sortable={false} /> */}
        <TextField source="phone" sortable={false} />
        <DateField source="createAt" showTime sortable={false} />
        <DateField source="deletedAt" showTime sortable={false} />
      </SimpleShowLayout>

      <SimpleShowLayout>
        <ClientForm currentClient={record} />
      </SimpleShowLayout>
    </>
  );
};

// Делаем обёртку Show, что бы полу получить данные record, получается создается PostShow, иначе будет undefined
export const ClientsPostShow = () => (
  <>
    <Show>
      <ClientsShowLayout />
    </Show>
  </>
);
