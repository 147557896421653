import React, { useState, forwardRef } from 'react';
import administrators from 'entities/administrators';
import posts from 'entities/posts';
import clients from 'entities/clients';
import Box from '@mui/material/Box';
import { MenuItemLink, useLogout, UserMenu, useSidebarState } from 'react-admin';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { MenuItem } from '@material-ui/core';

const MenuView = ({ dense = false }) => {
  const [menuItemsList, setMenuItemsList] = useState({
    menuTest: true
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: any) => {
    setMenuItemsList((prevState: any) => ({ ...prevState, [menu]: !prevState[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1
      }}
    >
      {/* <MenuItemLink
        to="/posts"
        state={{ _scrollToTop: true }}
        primaryText="Posts"
        leftIcon={<posts.icon />}
        dense={dense}
      /> */}
      <MenuItemLink
        to="/admin"
        state={{ _scrollToTop: true }}
        primaryText="Administrators"
        leftIcon={<administrators.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/clients"
        state={{ _scrollToTop: true }}
        primaryText="Clients"
        leftIcon={<clients.icon />}
        dense={dense}
      />
      {/* <MenuItemLink
        to="/comments"
        state={{ _scrollToTop: true }}
        primaryText="Comments"
        // leftIcon={<lots.icon />}
        dense={dense}
      /> */}
      {/* <MyLogoutButton /> */}
      {/* <SubMenu
        handleToggle={() => handleToggle('menuTest')}
        isOpen={menuItemsList.menuTest}
        name="Услуги"
        icon={<photos.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/photos"
          state={{ _scrollToTop: true }}
          primaryText="Фотографии"
          leftIcon={<photos.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/translations"
          state={{ _scrollToTop: true }}
          primaryText="Переводы"
          leftIcon={<translations.icon />}
          dense={dense}
        />
      </SubMenu> */}
    </Box>
  );
};

export const Menu = MenuView;
