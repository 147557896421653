/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
import { stringify } from 'query-string';
import RestAPI from 'api/rest';
import { httpClient } from 'admin';
import { apiUrl } from 'constants/index';

// export const ClientsAPI = {
//   getList: async (resource: any, params: any) => {
//     const { page } = params.pagination;
//     const quantity = params.pagination.perPage;
//     const searchByLot = params.filter.search;
//     const searchById = params.filter.byId;
//     const query = {
//       page,
//       quantity,
//       lotid: searchById,
//       lotnumber: searchByLot
//     };
//     return RestAPI.get(`/api/${resource}/all/?${stringify(query)}`).then(({ data }) => ({
//       data: data.clients,
//       total: data.meta.total
//     }));
//   },

//   create: async (resource: any, params: any) => {
//     // console.log(params);
//     console.log(resource, params);
//     // await RestAPI.post(`/api/${resource}`, { ...params.data });
//     // return { data: { ...params.data, id: Date.now() } };

//     // return { data: { ...params.data, id: Date.now() } };
//     // return { data: { ...params.data, id:} };

//     // return RestAPI.post(`/api/${resource}/create/?${stringify(query)}`).then(({ data }) => ({ data: data.admins, total: data.meta.total }));
//   }
// };

const baseURL = process.env.REACT_APP_API_URL || '';

export const dataProviderClient = {

  createAdmin: 'create',
  createClient: '',

  getAdmins: 'all',
  getClients: 'all',

  getList: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      page: page - 1,
      quantity: perPage
    };

    // console.log(httpClient(url).then(({ headers, json }: any) => console.log(json)) );

    return RestAPI.get(`/api/${resource}/all/?${stringify(query)}`).then(({ data }: any) => ({
      data: data.clients,
      total: data.meta.total
    }));
  },
  
  getOne: (resource: any, params: { id: any }) => RestAPI.get(`/api/${resource}/${params.id}`).then(({ data }: any) => (
    { data, id: params?.id }
  )),

  changeClientActivityStatus: (params: any) => {
    
    RestAPI.delete(`/api/clients/${params.id}`);

    return { data: true };
  },

  getMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const url = `${baseURL}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json.map((resource: { _id: any }) => ({ ...resource, id: resource._id }))
    }));
  },

  getManyReference: (
    resource: any,
    params: {
      pagination: { page: any; perPage: any };
      sort: { field: any; order: any };
      filter: any;
      target: any;
      id: any;
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id
      })
    };
    const url = `${`${process.env.REACT_APP_API_URL}/api`}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }: any) => ({
      data: json.map((resource: { _id: any }) => ({ ...resource, id: resource._id })),
      total: parseInt(headers.get('content-range').split('/').pop(), 10)
    }));
  },

  update: (resource: any, params: { id: any; data: any }) => {
    const newData = { ...params.data, password: params.data.passwordField };
    delete newData.passwordField;
    console.log(newData);
    return (httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(newData)
    }).then(({ data }: any) => ({ data, id: params.id })));

  },

  updateMany: (resource: any, params: { ids: any; data: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource: any, params: { data: any }) =>
    RestAPI.post(`/api/${resource}`, params.data).then(({ data }: any) => 
      // console.log(data);
      // console.log(params.data);
      ({ data: { ...params.data, id: data.id } })
    ),

  // httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}/create`, {
  //   method: 'POST',
  //   body: JSON.stringify(params.data)
  // }).then(({ json }) => {
  //   console.log(json);
  //   return { data: { ...params.data, id: json.id } };
  // }),

  delete: async (params: { id: any }) => {
    RestAPI.delete(`/api/clients/${params.id}`);
    return { data: true };
  },

  deleteMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  }
};
