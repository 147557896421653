import { CardActions } from '@material-ui/core';
import * as React from 'react';
import { List, Datagrid, TextField, TextInput, ShowButton, TopToolbar, CreateButton, EditButton, Edit, DeleteButton } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AdministratorsFilters = [
  <TextInput source="q" label="Search by title" alwaysOn />,
  <TextInput source="id" label="Search by id" alwaysOn />,
];

const AdministratorsActions = () => (
  <TopToolbar>
    <CreateButton label="Create an admin" />
    <EditButton icon={< EditIcon />} label='Edit an admin'/>
    <DeleteButton icon={<DeleteIcon />} label='Delete an admin'/>
  </TopToolbar>
);

export const AdministratorsList = () => (
  <List actions={<AdministratorsActions />} filter={{ commentable: true }} filters={AdministratorsFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="login" />
      <TextField source="createAt" />
      {/* <ShowButton /> */}
    </Datagrid>
  </List>
);
