import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ShowButton,
  TopToolbar,
  CreateButton,
  EditButton,
  useRecordContext,
  useListContext
} from 'react-admin';

const ClientsFilters = [
  <TextInput source="q" label="Search by title" alwaysOn />,
  <TextInput source="id" label="Search by id" alwaysOn />
];

const ClientsActions = () => (
  <TopToolbar>
    <CreateButton label="Create a client" />
    <EditButton icon={<EditIcon />} label="Edit a client" />
  </TopToolbar>
);

const userRowStyle = (record: any) => {
  // console.log(record);
};

export const ClientsListLayout = (props: any) => {
  const { data } = useListContext();

  return (
      <Datagrid rowClick="show" bulkActionButtons={false} rowStyle={userRowStyle}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
        <ShowButton />
      </Datagrid>
  );
};

export const ClientsPostList = () => (
  <>
    <List actions={<ClientsActions />} filter={{ commentable: true }} filters={ClientsFilters}>
      <ClientsListLayout />
    </List>
  </>
);
