import PeopleIcon from '@mui/icons-material/People';
import { ClientsPostList } from './ClientsList';
import { ClientsPostShow } from './ClientsShow';
import { ClientsCreate } from './ClientsCreate';
import { ClientsPostEdit } from './ClientsrsEdit';

export default {
  list: ClientsPostList,
  show: ClientsPostShow,
  edit: ClientsPostEdit,
  create: ClientsCreate,
  options: { label: 'Clients' },
  icon: PeopleIcon
};
