/* eslint-disable no-param-reassign */
import React, { FC } from 'react';
import { Admin, Resource, ListGuesser, defaultTheme, fetchUtils, combineDataProviders } from 'react-admin';
import { AuthAPI } from 'api/payway/auth';
// import { dataProvider } from 'api/payway/index';
// import dataProvider from 'api/payway/providerComposite/index';
// import { dataProvider } from 'api/payway';
import { CustomLayout } from 'layout/LayoutView';
import jsonServerProvider from 'ra-data-json-server';
import MyLoginPage from 'layout/LoginPageView/index';
import administrators from 'entities/administrators';
import posts from 'entities/posts';
import clients from 'entities/clients';
// import { dataProviderNew } from 'api/payway';
import { dataProviderAdmin } from 'api/payway/administrators';
import { dataProviderClient } from 'api/payway/clients';
import MyNotification from 'notification';
import { dataProvider } from 'api/payway';

export const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  options.headers.set('X-Custom-Header', 'foobar');
  return fetchUtils.fetchJson(url, options);
};

// const dataProvider = simpleRestProvider('https://api.payway.asap-lp.ru/api', httpClient);

// const dataProviderSwitch = combineDataProviders((resource) => {
//   switch (resource) {
//     case 'admin':
//       return dataProviderAdmin;
//     case 'clients':
//       return dataProviderClient;
//     default:
//       throw new Error(`Unknown resource: ${resource}`);
//   }
// });

// dataProvider.create('admin');
export const AdminView: FC = () => (
  <Admin
    notification={MyNotification}
    // loginPage={MyLoginPage}
    // dataProvider={dataProvider}
    dataProvider={dataProvider}
    authProvider={AuthAPI}
    layout={CustomLayout}
  >
    <Resource name="admin" {...administrators} />
    <Resource name="clients" {...clients} />
  </Admin>
);

export default AdminView;
