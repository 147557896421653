import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { AdministratorsList } from './AdministratorsList';
import { AdministratorsPostShow } from './AdministratorsShow';
import { AdministratorsCreate } from './AdministratorsCreate';
import { AdministratorsEdit } from './AdministratorsEdit';

export default {
  list: AdministratorsList,
  show: AdministratorsPostShow,
  edit: AdministratorsEdit,
  create: AdministratorsCreate,
  options: { label: 'Administrators' },
  icon: AdminPanelSettingsIcon
};
